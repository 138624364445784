/* LIGHT */
body.light {
  background-color: rgba(255, 255, 255);
  color: #333333;
}

body.light .dx-fileuploader-wrapper {
  padding: 7px;
  border: 1px solid lightgray;
}
body.light .dx-fileuploader-show-file-list .dx-fileuploader-files-container {
  padding-top: 0px !important;
}
body.light i[data-icon-name='ProgressRingDots'] {
  color: rgb(16, 110, 190);
}

body.light .dx-htmleditor {
  border-color: #ddd;
}

/* .linkContent-124 >  .text-125 { 
  margin-left: 0px !important;
} */

.ms-Pivot-linkContent > span.ms-Pivot-text {
  margin-left: 0px;
}

/* DARK */

body.dark {
  background-color: rgb(83,83,83);
  color: #c7c7c7;
}

/* RichTextField lines for dark mode*/

body.dark .ql-editor.ql-blank::before {
  color: #a2a2a2;
}

body.dark .dx-htmleditor {
  border-color: #6d6d6d;
}

body.dark .dx-htmleditor:hover {
  border-color: #c7c7c7;
}

body.dark .dx-htmleditor:hover .dx-htmleditor-toolbar-wrapper {
  border-color: #c7c7c7 !important;
}

body.dark .dx-htmleditor:focus-within {
  border-color: #fff;
}

body.dark .dx-htmleditor:focus-within .dx-htmleditor-toolbar-wrapper {
  border-color: #fff !important;
}

body.dark .dx-htmleditor .dx-htmleditor-toolbar-wrapper:first-child {
  border-color: #6d6d6d;
}

/*MS COLORS*/
body.dark .ms-bgColor-themePrimary,
body.dark .ms-bgColor-themePrimary--hover:hover {
  background-color: white;
}

body.dark .ms-fontColor-themePrimary,
body.dark .ms-fontColor-themePrimary--hover:hover {
  color: lightgray;
}

body.dark .ms-ActivityItem a {
  color: lightgray;
}

body.dark .ms-ActivityItem a:hover {
  color: white;
}

body.dark .ms-PeoplePicker .ms-BasePicker-input {
  background-color: transparent;
  color: white;
}
body.dark .ms-PeoplePicker-searchBox {
  background-color: transparent;
}
body.dark .ms-MessageBar-icon i {
  color: #6d6d6d;
}
body.dark .ms-MessageBar-text {
  color: #6d6d6d;
}
body.dark .ms-Fabric {
  color: lightgray;
}

/*SIGN IN*/
body.dark .form-signIn {
  color: white;
}
body.dark i[data-icon-name='ProgressRingDots'] {
  color: white;
}
/*ABOUT*/

body.dark .success-section {
  color: greenyellow;
}

body.dark .warn-section {
  color: orange;
}

/*WIZARDS*/
body.dark .page-content {
  color: white;
}

body.dark .page-content a {
  color: lightgray;
}

body.dark .page-content a:hover {
  color: white;
}

/* quill */
/*
body.dark .ql-snow .ql-stroke {
  stroke: lightgray;
}

body.dark .ql-snow .ql-picker .ql-picker-label {
  color: lightgray;
}

body.dark .ql-snow.ql-toolbar button:hover,
body.dark .ql-snow .ql-toolbar button:hover,
body.dark .ql-snow.ql-toolbar button:focus,
body.dark .ql-snow .ql-toolbar button:focus,
body.dark .ql-snow.ql-toolbar button.ql-active,
body.dark .ql-snow .ql-toolbar button.ql-active,
body.dark .ql-snow.ql-toolbar .ql-picker-label:hover,
body.dark .ql-snow .ql-toolbar .ql-picker-label:hover,
body.dark .ql-snow.ql-toolbar .ql-picker-label.ql-active,
body.dark .ql-snow .ql-toolbar .ql-picker-label.ql-active {
  color: white;
}

body.dark .ql-snow.ql-toolbar button:hover .ql-fill,
body.dark .ql-snow .ql-toolbar button:hover .ql-fill,
body.dark .ql-snow.ql-toolbar button:focus .ql-fill,
body.dark .ql-snow .ql-toolbar button:focus .ql-fill,
body.dark .ql-snow.ql-toolbar button.ql-active .ql-fill,
body.dark .ql-snow .ql-toolbar button.ql-active .ql-fill,
body.dark .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
body.dark .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
body.dark .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
body.dark .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
body.dark .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
body.dark .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
body.dark .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
body.dark .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
body.dark .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
body.dark .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
body.dark .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
body.dark .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
body.dark .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
body.dark .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
body.dark .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
body.dark .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
body.dark .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
body.dark .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
body.dark .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
body.dark .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: white;
}
body.dark .ql-snow.ql-toolbar button:hover .ql-stroke,
body.dark .ql-snow .ql-toolbar button:hover .ql-stroke,
body.dark .ql-snow.ql-toolbar button:focus .ql-stroke,
body.dark .ql-snow .ql-toolbar button:focus .ql-stroke,
body.dark .ql-snow.ql-toolbar button.ql-active .ql-stroke,
body.dark .ql-snow .ql-toolbar button.ql-active .ql-stroke,
body.dark .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
body.dark .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
body.dark .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
body.dark .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
body.dark .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
body.dark .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
body.dark .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
body.dark .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
body.dark .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
body.dark .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
body.dark .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
body.dark .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
body.dark .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
body.dark .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter {
  stroke: white;
}

body.dark .ql-snow a {
  color: white;
}

body.dark .ql-editor.ql-blank::before {
  color: lightgray;
}
*/

/*DX*/
body.dark .dx-fileuploader-wrapper {
  padding: 7px;
  border: 1px solid #dedede;
}
body.dark .dx-fileuploader-show-file-list .dx-fileuploader-files-container {
  padding-top: 0px !important;
}
body.light .attachmentsUploader .dx-fileuploader-wrapper {
  border: 1px solid #8a8886;
}
body.light .attachmentsUploader .dx-fileuploader-wrapper:hover {
  border: 1px solid #323130;
}
body.dark .attachmentsUploader .dx-fileuploader-wrapper {
  border: 1px solid #8a8886;
}
body.dark .attachmentsUploader .dx-fileuploader-wrapper:hover {
  border: 1px solid #c7c7c7;
}
body.dark .dx-fileuploader-input {
  width: auto;
}
body.light .dx-fileuploader-input {
  width: auto;
}

/* Sidebar menu items */
.ms-Panel-commands .ms-Panel-closeButton:focus {
  outline: none;
}
.ms-Panel-commands .ms-Panel-closeButton .ms-Button-flexContainer:focus {
  outline: none;
}
a.ms-Button.ms-Button--action.ms-Button--command.ms-Nav-link {
  text-decoration: none;
}

/* Top bar */
body.light #toolbarPortal div.ms-FocusZone.ms-CommandBar {
  background: #f4f4f4;
}
body.dark #toolbarPortal div.ms-FocusZone.ms-CommandBar {
  background: #626262;
}

/* Top bar buttons */
body.light
  #toolbarPortal
  button.ms-Button.ms-Button--commandBar.ms-CommandBarItem-link {
  background: #f4f4f4;
}
body.light
  #toolbarPortal
  button.ms-Button.ms-Button--commandBar.ms-CommandBarItem-link:hover {
  background: #eaeaea;
}
#toolbarPortal button.ms-Button.ms-Button--commandBar.ms-CommandBarItem-link:focus {
  outline: none;
}
body.dark #toolbarPortal button.ms-Button.ms-Button--commandBar.ms-CommandBarItem-link {
  background: #626262;
}
body.dark
  #toolbarPortal
  button.ms-Button.ms-Button--commandBar.ms-CommandBarItem-link:hover {
  background: #6d6d6d;
}
body.light
  #toolbarPortal
  button.ms-Button.ms-Button--commandBar.ms-Button--hasMenu.ms-CommandBar-overflowButton {
  background: #f4f4f4; /*#626262*/
}
body.light
  #toolbarPortal
  button.ms-Button.ms-Button--commandBar.ms-Button--hasMenu.ms-CommandBar-overflowButton:hover {
  background: #eaeaea; /*#6d6d6d*/
}

body.light #toolbarPortal .ms-Icon.root-37.ms-Button-menuIcon {
  color: #0078d4;
}
body.light #toolbarPortal .ms-Icon.root-37.ms-Button-menuIcon:hover {
  background: #eaeaea;
}

body.dark
  #toolbarPortal
  button.ms-Button.ms-Button--commandBar.ms-Button--hasMenu.ms-CommandBar-overflowButton {
  background: #626262;
}
body.dark
  #toolbarPortal
  button.ms-Button.ms-Button--commandBar.ms-Button--hasMenu.ms-CommandBar-overflowButton:hover {
  background: #6d6d6d;
}

body.dark #toolbarPortal .ms-Icon.ms-Button-menuIcon {
  color: #d4d4d4;
}
body.dark #toolbarPortal .ms-Icon.ms-Button-menuIcon:hover {
  background: #6d6d6d;
}

/* Content buttons */
.ms-Button.ms-Button--action.ms-Button--command:focus,
.ms-Button.ms-Button--default:focus,
div.ms-Stack > button {
  outline: none;
}

/* Spinner styling */
body.dark .ms-Spinner-circle {
  border-color: #6d6d6d rgb(255, 255, 255) rgb(255, 255, 255);
}

body.dark .ms-Spinner-label {
  color: #d5d5d5;
}

body.light .ms-Spinner-circle {
  border-color: rgb(0, 120, 212) rgb(199, 224, 244) rgb(199, 224, 244);
}

body.light .ms-Spinner-label {
  color: #6d6d6d;
}

.lookup-field {
  display: table;
  width: 100%;
  table-layout: fixed;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.selectorIconButton {
  width: 26px !important;
  height: 26px !important;
}

